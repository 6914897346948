var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "flex": "1",
      "pb": "5rem"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex": "1",
      "flex-dir": "column",
      "px": "20px"
    }
  }, [!_vm.isEmpty ? _c('c-flex', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        gap: '10px'
      },
      expression: "{ gap: '10px' }"
    }],
    attrs: {
      "mt": "10px",
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "h": "24px",
      "w": "24px",
      "src": require('@/assets/icon-user-filled.svg'),
      "alt": "icon user"
    }
  }), _c('c-text', {
    attrs: {
      "font-size": "12px",
      "font-weight": "400",
      "color": "gray.900"
    }
  }, [_vm._v(" Dibuat oleh " + _vm._s(_vm.nutritionistName) + " - Ahli gizi Dietela ")])], 1) : _vm._e(), _c('c-text', {
    attrs: {
      "mt": "15px",
      "font-size": "16px",
      "font-weight": "700"
    }
  }, [_vm._v(" Kondisi Giziku ")]), _vm.isKondisiGizikuEmpty ? _c('c-box', [_c('EmptyState', {
    attrs: {
      "title": "Kondisi giziku belum terisi"
    }
  })], 1) : [_c('c-flex', {
    attrs: {
      "mt": "15px",
      "flex-dir": "column",
      "border": "1px solid #C4C4C4",
      "border-radius": "8px",
      "p": "8px"
    }
  }, [_c('c-flex', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        gap: '10px'
      },
      expression: "{ gap: '10px' }"
    }],
    attrs: {
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "12px",
      "font-weight": "400"
    }
  }, [_vm._v(" Indeks Masa Tubuh ")]), _c('c-image', {
    attrs: {
      "h": "18px",
      "w": "18px",
      "src": require('@/assets/icon-info-circle.svg'),
      "alt": "icon info"
    }
  })], 1), _c('c-text', {
    attrs: {
      "mt": "5px",
      "font-size": "16px",
      "font-weight": "700"
    }
  }, [_vm._v(" " + _vm._s(_vm.bmi) + "kg/m2 ")]), _c('c-text', {
    attrs: {
      "mt": "5px",
      "font-size": "14px",
      "font-weight": "400",
      "color": "#555"
    }
  }, [_vm._v(" Status gizi mu: " + _vm._s(_vm.statusGizi) + " ")]), _c('ProgressComp', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "value": _vm.progressValue,
      "mt": "10px"
    }
  })], 1), _vm.profileGizi ? _c('c-flex', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        gap: '8px'
      },
      expression: "{ gap: '8px' }"
    }],
    attrs: {
      "mt": "10px",
      "overflow-x": "auto"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "border": "1px solid #c4c4c4",
      "border-radius": "8px",
      "p": "8px",
      "flex-shrink": "0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "12px",
      "font-weight": "400",
      "color": "#4F4F4F"
    }
  }, [_vm._v(" Umur ")]), _c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "700",
      "color": "#111"
    }
  }, [_vm._v(" " + _vm._s(_vm.profileGizi.age) + " Tahun ")])], 1), _c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "border": "1px solid #c4c4c4",
      "border-radius": "8px",
      "p": "8px",
      "flex-shrink": "0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "12px",
      "font-weight": "400",
      "color": "#4F4F4F"
    }
  }, [_vm._v(" Berat badan/Tinggi badan ")]), _c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "700",
      "color": "#111"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("toFixed")(_vm.profileGizi.weight)) + "kg / " + _vm._s(_vm._f("toFixed")(_vm.profileGizi.height)) + "cm ")])], 1), _c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "border": "1px solid #c4c4c4",
      "border-radius": "8px",
      "p": "8px",
      "flex-shrink": "0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "12px",
      "font-weight": "400",
      "color": "#4F4F4F"
    }
  }, [_vm._v(" BB ideal ")]), _c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "700",
      "color": "#111"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("toFixed")(_vm.profileGizi.idealWeightMinimum)) + " - " + _vm._s(_vm._f("toFixed")(_vm.profileGizi.idealWeightMaximum)) + " kg ")])], 1)], 1) : _vm._e()], _c('c-flex', {
    attrs: {
      "my": "10px",
      "align-items": "center",
      "justify": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "mt": "10px",
      "font-size": "16px",
      "font-weight": "700",
      "color": "#333"
    }
  }, [_vm._v(" Asupan Gizi ")]), _vm.isNutri ? _c('c-button', {
    attrs: {
      "variant-color": "primary",
      "border-radius": "50px",
      "as": "router-link",
      "to": {
        name: 'nutri.nutrition-edit'
      }
    }
  }, [_c('c-image', {
    attrs: {
      "mr": "10px",
      "src": require('@/assets/icon-pencil.svg'),
      "alt": "icon"
    }
  }), _vm._v(" Edit ")], 1) : _vm._e()], 1), _vm.isAsupanGiziEmpty ? _c('c-box', [_c('EmptyState', {
    attrs: {
      "title": "Asupan gizi belum terisi"
    }
  })], 1) : _c('c-flex', {
    attrs: {
      "flex-dir": ['column', 'row'],
      "flex-wrap": "wrap",
      "grid-gap": "10px"
    }
  }, _vm._l(_vm._asupanGizi, function (asupan) {
    return _c('card-kalori', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      key: asupan.title,
      attrs: {
        "title": asupan.title,
        "point-sekarang": asupan.pointSekarang,
        "point-kebutuhan": asupan.pointKebutuhan,
        "warning-text": asupan.catatan,
        "satuan": asupan.satuan,
        "w": ['auto', '360px']
      }
    });
  }), 1), _c('card-kondisi-tubuh', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "kondisi-tubuh": _vm.kondisiTubuh,
      "mt": "20px"
    }
  }), _vm.saran != null ? _c('card-saran', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "saran": _vm.saran,
      "mt": "20px"
    },
    on: {
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'nutri.nutrition-advice-edit'
        });
      }
    }
  }) : _vm._e()], 2), _c('card-ilustrasi', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "20px"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }