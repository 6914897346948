<template>
  <c-box px="20px">
    <c-flex
      flex-dir="column"
      h="273px"
      bg="#fbefc7"
      p="20px"
    >
      <c-flex>
        <c-text
          mt="55px"
          font-size="14px"
          font-weight="400"
          line-height="21px"
        >
          Temukan berbagai variasi golongan makan beserta takaran porsinya
        </c-text>
        <c-image
          w="120px"
          :src="require('@/assets/img-ilustrasi-ahli-gizi.svg')"
          alt="ilustrasi"
        />
      </c-flex>
      <c-button
        variant="outline"
        variant-color="primary"
        border-radius="65px"
        mt="35px"
        bg="white"
        as="router-link"
        :to="{name: 'glossaries'}"
      >
        <c-text
          font-size="12px"
          font-weight="700"
          line-height="18px"
          color="primary.400"
        >
          Lihat glossari foto makan
        </c-text>
        <c-image
          :src="require('@/assets/icon-chevron-right-green.svg')"
          alt="icon arrow"
          ml="10px"
        />
      </c-button>
    </c-flex>
  </c-box>
</template>

<script>
export default {
  name: 'CardIlustrasi',
}
</script>
