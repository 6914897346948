var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "px": "20px"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "h": "273px",
      "bg": "#fbefc7",
      "p": "20px"
    }
  }, [_c('c-flex', [_c('c-text', {
    attrs: {
      "mt": "55px",
      "font-size": "14px",
      "font-weight": "400",
      "line-height": "21px"
    }
  }, [_vm._v(" Temukan berbagai variasi golongan makan beserta takaran porsinya ")]), _c('c-image', {
    attrs: {
      "w": "120px",
      "src": require('@/assets/img-ilustrasi-ahli-gizi.svg'),
      "alt": "ilustrasi"
    }
  })], 1), _c('c-button', {
    attrs: {
      "variant": "outline",
      "variant-color": "primary",
      "border-radius": "65px",
      "mt": "35px",
      "bg": "white",
      "as": "router-link",
      "to": {
        name: 'glossaries'
      }
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "12px",
      "font-weight": "700",
      "line-height": "18px",
      "color": "primary.400"
    }
  }, [_vm._v(" Lihat glossari foto makan ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/icon-chevron-right-green.svg'),
      "alt": "icon arrow",
      "ml": "10px"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }