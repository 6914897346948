var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "flex": "1"
    }
  }, [_c('c-flex', {
    attrs: {
      "mb": "10px",
      "px": "20px",
      "align-items": "center",
      "justify": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "heading",
      "font-size": "18px",
      "font-weight": "700"
    }
  }, [_vm._v(" Profil Gizi ")]), !_vm.isNutri ? _c('c-button', {
    attrs: {
      "variant": "outline",
      "variant-color": "primary",
      "rounded": "lg",
      "as": "router-link",
      "to": {
        query: {
          intro: 1
        }
      }
    }
  }, [_c('c-text', [_vm._v("Info")]), _c('c-image', {
    attrs: {
      "ml": "5px",
      "src": require('@/assets/ic-help-filled.svg'),
      "alt": "icon help"
    }
  })], 1) : _vm._e(), _vm.isNutri ? _c('c-button', {
    attrs: {
      "variant-color": "primary",
      "border-radius": "50px",
      "as": "router-link",
      "to": {
        name: 'nutri.profile-client-edit'
      }
    }
  }, [_c('c-image', {
    attrs: {
      "mr": "10px",
      "src": require('@/assets/icon-pencil.svg'),
      "alt": "icon"
    }
  }), _vm._v(" Edit ")], 1) : _vm._e()], 1), _vm.isEmpty ? _c('Empty', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "80px"
    }
  }) : _vm._e(), !_vm.isEmpty ? _c('NonEmpty') : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }